<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import useDamageReports from "../../../hooks/useDamageReports.js";
import { setPageTitle } from "@/utils/pageTitle";
import DamageReportsLayout from "@/layouts/DamageReportsLayout.vue";
import { useUserStore } from "@/stores/user";
import gravatarUrl from "gravatar-url";

const route = useRoute();
const notes = ref([]);
const showCreateNote = ref(false);
const newNote = ref({
  id: null,
  at_workflow_state: null,
  note: null,
});
const noteWorkflowStates = (state) => {
  let states = [
    { label: "New", value: "new" },
    { label: "Processing", value: "processing" },
    { label: "Reviewing", value: "reviewing" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
    { label: "Finalized", value: "finalized" },
  ];
  switch (state) {
    case "new":
      return states.slice(1);
    case "processing":
      return states.slice(2);
    case "reviewing":
      return states.slice(3);
    case "approved":
      return states.slice(4);
    case "rejected":
      return states.slice(5);
    case "finalized":
      return states.slice(6);
    default:
      return states.slice(1);
  }
};
const { user } = useUserStore();

const {
  fetchDamage,
  fetchDamageNotes,
  damage,
  workflowStateColor,
  damageTypeColor,
  addDamageNote,
  deleteDamageNote,
} = useDamageReports();

onMounted(async () => {
  await fetchDamage(route.params.id);
  await refreshNotes();
  setPageTitle(`Damage Report // ${damage.value.id}`);
});

const refreshNotes = async () => {
  notes.value = await fetchDamageNotes(route.params.id);
};

const addNote = async () => {
  try {
    await addDamageNote(route.params.id, {
      at_workflow_state: newNote.value.at_workflow_state,
      note: newNote.value.note,
    });
    await refreshNotes();
    cancelAddNote();
  } catch (error) {
    console.error(error);
  }
};

const cancelAddNote = () => {
  showCreateNote.value = false;
  newNote.value = {
    id: null,
    at_workflow_state: null,
    note: null,
  };
};

const deleteNote = async (noteId) => {
  const confirmation = confirm("Are you sure you want to delete this note?");
  if (!confirmation) return;
  try {
    await deleteDamageNote(route.params.id, noteId);
    await refreshNotes();
  } catch (error) {
    console.error(error);
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};
</script>

<template>
  <DamageReportsLayout v-if="damage" :damage="damage" title="Description">
    <v-card-title class="bg-surface-dark">Report Details</v-card-title>
    <v-card-text>
      <v-row class="row mt-4">
        <v-col cols="3">
          <div class="text-h5">Client</div>
          <div>
            <a :href="`/clients/${damage.client?.id}`">{{ damage.client?.name }}</a>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-h5">Order</div>
          <div>
            <!-- need to reconfirm the value of order_type -->
            <a
              v-if="damage.order_type == 'Models::InboundOrder'"
              :href="`/inbound/${damage.warehouse.id}`"
              >{{ damage.order.number }}</a
            >
            <a
              v-else-if="damage.order_type == 'Models::OutboundOrder'"
              :href="`/outbound/${damage.warehouse.id}`"
              >{{ damage.order.number }}</a
            >
            <span v-else-if="damage.order_id == null">n/a</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-h5">Warehouse</div>
          <div>
            <a :href="`/warehouses/${damage.warehouse?.id}`">{{
              damage.warehouse?.name
            }}</a>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-h5">Damage Type</div>
          <div>
            <v-chip :color="damageTypeColor(damage.damage_type)" flat>{{
              damage?.damage_type?.replace("_", " ")
            }}</v-chip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title class="bg-surface-dark">Damaged Products</v-card-title>

    <v-table class="border-t-md">
      <thead>
        <tr class="">
          <th class="text-left border-b-sm">LPN</th>
          <th class="text-left border-b-sm">Product SKU</th>
          <th class="text-left border-b-sm">Quantity</th>
          <th class="text-left border-b-sm">Moved To</th>
          <th class="text-left border-b-sm">Bin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <a :href="`/lps/${damage?.lp?.id}`">{{ damage?.lp?.number }}</a>
          </td>
          <td>
            <a v-if="damage.lp?.product" :href="`/products/${damage.lp.product_id}`">{{
              damage.lp?.product.sku
            }}</a>
          </td>
          <td>{{ damage.qty_damaged }} {{ damage.lp?.product.units }}</td>
          <td>
            <a v-if="damage.destination_lp" :href="`/lps/${damage.destination_lp_id}`">
              <v-chip size="small" variant="flat" color="primary">{{
                damage.destination_lp_id
              }}</v-chip>
            </a>
          </td>
          <td>
            <a :href="`/lps/${damage.damage_bin_id}`">
              <v-chip size="small" variant="flat" color="primary">{{
                damage.damage_bin_id
              }}</v-chip>
            </a>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-card flat class="mt-8">
      <v-card-title class="bg-surface-dark d-flex justify-space-between align-center">
        <span class="text-h6">Notes</span>
        <v-btn
          @click="showCreateNote = !showCreateNote"
          :color="showCreateNote ? 'error' : 'success'"
          :prepend-icon="showCreateNote ? 'mdi-close' : 'mdi-plus'"
          :variant="showCreateNote ? 'text' : 'text'"
          elevation="0"
        >
          {{ showCreateNote ? "Cancel" : "Add Note" }}
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <v-card v-if="showCreateNote" class="mt-4">
          <v-card-title>Add Note</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="newNote.note"
              label="Note"
              rows="3"
              hide-details
              class="mb-4"
            ></v-textarea>
            <v-select
              v-model="newNote.at_workflow_state"
              :items="workflowStates"
              item-title="label"
              item-value="value"
              label="At Status"
              hide-details
              class="mb-4"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelAddNote" color="error">Cancel</v-btn>
            <v-btn @click="addNote" color="primary">Add Note</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>

      <v-card flat v-for="note in notes" :key="note.id" class="mt-4 border-b rounded-0">
        <v-card-item>
          <template v-slot:prepend>
            <v-avatar
              :image="gravatarUrl(note.created_by.email, { size: 40, default: 'retro' })"
              size="40"
            ></v-avatar>
          </template>
          <v-card-title class="d-flex align-center justify-space-between">
            {{ note.created_by.name }}
            <div class="text-right align-center">
              <v-chip
                :color="workflowStateColor(note.at_workflow_state)"
                variant="flat"
                size="small"
                class="mr-2"
              >
                Status: {{ note.at_workflow_state }}
              </v-chip>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <span class="text-caption">{{ formatDate(note.created_at) }}</span>
          </v-card-subtitle>
        </v-card-item>

        <v-card-text class="mt-2">
          {{ note.note }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="user.superadmin == true"
            @click="deleteNote(note.id)"
            color="error"
            icon="mdi-delete"
            variant="text"
            size="small"
          ></v-btn>
        </v-card-actions>
      </v-card>

      <v-card-text v-if="notes.length === 0" class="text-center py-8">
        <v-icon icon="mdi-note-text-outline" size="48" color="grey"></v-icon>
        <p class="mt-2 text-body-1">No notes available</p>
      </v-card-text>
    </v-card>
  </DamageReportsLayout>
</template>

<style scoped>
.text-h5 {
  /*needs to be inside scope different font used in other components */
  font-family: "Source Sans 3", "sans-serif" !important;
  font-size: 1rem !important;
  font-weight: bold;
}
</style>
